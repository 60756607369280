import Post1 from "./Post1"
import Post2 from "./Post2"
import Post3 from "./Post3"

const Posts = {
  Post1: <Post1 />,
  Post2: <Post2 />,
  Post3: <Post3 />
}
 
export default Posts