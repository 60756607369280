import React from 'react'

export default function index() {
  return (
    <div className='h-[100vh] w-[100%] bg-white snap-container biraireality'>
      <nav className='w-full relative bg-gray-50 h-[5rem] justify-end items-center px-[20rem] flex snap-center'>
        <img alt='' className='w-[5rem] h-[5rem] absolute top-[1rem] left-[10rem]' src={require('../../assets/logo.png')}/>
        <ul className='flex gap-10'>
          <li>
            About
          </li>
          <li>
            Career
          </li>
          <li>
            Products
          </li>
        </ul>
      </nav>
      <section id='home' className='h-[100vh]snap-center'>

      </section>
      <section id='about' className='h-[100vh]  snap-center'>

      </section>
      <section id='products' className='h-[100vh] snap-center'>

      </section>
      <section id='testimonial' className='h-[100vh]   snap-center'>

      </section>
    </div>
  )
}
