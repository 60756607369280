const nav = [
  // { label: 'Home', name: 'home', link: '#top' },
  { label: 'Services', name: 'services', link: '#services' },
  { label: 'About', name: 'about', link: '#about' },
  { label: 'Portfolio', name: 'portfolio', link: '#portfolio' },
  // { label: 'Pricing', name: 'pricing', link: '#pricing' },
  { label: 'Testimonial', name: 'testimonial', link: '#testimonial' },
  { label: 'Blog', name: 'blog', link: '#blog' },
  { label: 'Careers', name: 'Careers', link: 'careers' },
  { label: 'Timeline', name: 'timeline', link: '#timeline' },
  // { label: 'Referal Partners', name: 'contact', link: '' },
  { label: 'Contact Us', name: 'contact', link: '#contact' },
]

export default nav