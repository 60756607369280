import React, { useState } from 'react'
import Top from '../pages/landing-page/Top'
import Services from '../pages/landing-page/Services'
import About from '../pages/landing-page/About'
import Portfolio from '../pages/landing-page/Portfolio'
import Pricing from '../pages/landing-page/Pricing'
import Testimonial from '../pages/landing-page/Testimonial'
import Partners from '../pages/landing-page/Partners'
import Blog from '../pages/landing-page/Blog'
import Skills from '../pages/landing-page/Skills'
import Timeline from '../pages/landing-page/Timeline'
import Contact from '../pages/landing-page/Contact'
import Footer from '../layouts/Footer'
import Background from '../components/Background'
import AnimateWrapper from './AnimateWrapper'
export default function LandingPage() {



  return (
    <> 
     
      <section id='top' className='child'>
        <Top />
      </section>
      <section id='services' className='child pt-[5.5rem] lg:pt-20 z-10  bg-prmBG '>
        <AnimateWrapper>
          <Services /> 
        </AnimateWrapper>
      </section>
      <section id='about' className='child pt-20  bg-secBG'>
        <AnimateWrapper>
          <About />
        </AnimateWrapper>
      </section>
      <section id='portfolio' className='child pt-20 bg-secBG'>
      <AnimateWrapper>
        <Portfolio />
        </AnimateWrapper>
      </section>
      {/* <section id='pricing' className='pt-20'>
        <Pricing />
      </section> */}
      <section id='testimonial' className='pt-20 bg-prmBG child'>
      <AnimateWrapper>
        <Testimonial />
        </AnimateWrapper>
      </section>
      {/* <section id='partners' className='pt-20'>
        <Partners />
      </section> */}
      <section id='blog' className='pt-20  bg-secBG child'> 
       <Blog />  
      </section>
      {/* <section id='skills' className='pt-20 bg-[#1b212c]'>
        <Skills />
      </section> */}
      <section id='timeline' className='pt-20 bg-prmBG child'>
      <AnimateWrapper>
        <Timeline />
        </AnimateWrapper>
      </section>
      <section id='contact' className='pt-20 bg-secBG child'>
      <AnimateWrapper>
        <Contact />
        </AnimateWrapper>
      </section>
   
      </>
  )
}
