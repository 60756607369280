export const BlogData = [
  { id: "1", title: 'WHY VIRTUAL ASSISTANTS ARE GREAT FOR BUSINESSES', date: 'March 1, 2024', src: require('../assets/Blog Banner 1.png'), desc: 'During the pandemic, the world faced uncertainty, causing chaos in every country. The fear of illness affected everyone. However, amidst this, businesses had to rethink their economic strategies.' },  
  { id: "2", title: 'Essential Digital Tools for Successful Start-Ups', date: 'March 1, 2024', src: require('../assets/BLOG Banner- Essential Digital Tools.png'), desc: 'Are you ready to embark on your digital journey? Starting up online demands courage – the kind that pushes you to connect with people, influence perspectives, overcome challenges, and build a brand that truly reflects you.' },  
  { id: "3", title: 'Unlock Growth with SMS Marketing', date: 'March 1, 2024', src: require('../assets/Blog Banner- SMS Marketing.png'), desc: `SMS (Short Message Service) marketing is a strong tool that businesses
  use to talk to their audience through text messages.` },  
]
export const partnersData = [
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
  { name: '', src: 'https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80' },
]

export const logo = require('../assets/logo.png')

export const PortfolioData = [
  { type: 'Virtual Assistance', src: require('../assets/BLOG POST -1.png'), title: 'BLOG POST', subTitle: 'asdasd', row:2},
  { type: 'Virtual Assistance', src: require('../assets/BLOG POST-2.png'), title: 'LOGO CREATION', subTitle: 'asdasd', row: 2 }, 
  { type: 'Web Development', src: require('../assets/_2in1- WEB DEVELOPMENT.png'), title: 'asdasd', subTitle: 'dsad',row: 2 }, 
  { type: 'Web Development', src: require('../assets/_2in1- WEB DEVELOPMENT (1).png'), title: 'asdasd', subTitle: 'dsad',row: 2 },  
  { type: 'Digital Marketing', src: require('../assets/_ 2in1- DIGITAL MARKETING (5).png'), title: 'asdasd', subTitle: 'dsad',row: 2 }, 
  { type: 'Digital Marketing', src: require('../assets/_ 2in1- DIGITAL MARKETING (3).png'), title: 'asdasd', subTitle: 'dsad',row: 2 }, 
  { type: 'Virtual Assistance', src: require('../assets/LOGO CREATION.png'), title: 'LOGO CREATION', subTitle: 'asdasd', row: 2 },
  { type: 'Digital Marketing', src: require('../assets/_ 2in1- DIGITAL MARKETING (2).png'), title: 'asdasd', subTitle: 'dsad',row: 2 }, 
  { type: 'Digital Marketing', src: require('../assets/_ 2in1- DIGITAL MARKETING (4).png'), title: 'asdasd', subTitle: 'dsad',row: 2 }, 
  { type: 'SMS Marketing', src: require('../assets/sms marketing/2in1- SMS MARKETING.png'), title: 'asdasd', subTitle: 'dsad',row: 2 }, 
]

export const portfolioFilter = [
  { label: 'All', value: 'all' },
  { label: 'Virtual Assistance', value: 'Virtual Assistance' },
  { label: 'Web Development', value: 'Web Development' },
  { label: 'Digital Marketing', value: 'Digital Marketing' }, 
  { label: 'SMS Marketing', value: 'SMS Marketing' }, 
]


export const pricingData = [
  {
    title: 'start', 
    subTitle: 'For small business', 
    price: '57', 
    duration: 'mo', 
    offer: [
      { active: true, desc: 'Unlimited Support' },
      { active: true, desc: '5GB Server Space' },
      { active: true, desc: '2 Users per Project' },
      { active: false, desc: 'Free Domain' },
      { active: false, desc: 'Live Chat Support' },
      { active: false, desc: 'Email Integration' },
      { active: false, desc: 'Unlimited Download' },
    ] 
  },
  {
    title: 'FULL', 
    subTitle: 'Best buy for the price', 
    price: '59', 
    duration: 'mo', 
    offer: [
      { active: true, desc: 'Unlimited Support' },
      { active: true, desc: '5GB Server Space' },
      { active: true, desc: '2 Users per Project' },
      { active: false, desc: 'Free Domain' },
      { active: false, desc: 'Live Chat Support' },
      { active: false, desc: 'Email Integration' },
      { active: false, desc: 'SEO Report' },
      { active: false, desc: 'Daily Backups' },
      { active: false, desc: 'Google Analytics' },
      { active: false, desc: 'Unlimited Download' },
    ] 
  },
  {
    title: 'BASIC', 
    subTitle: 'For medium businesses', 
    price: '49', 
    duration: 'mo', 
    offer: [
      { active: true, desc: 'Unlimited Support' },
      { active: true, desc: '5GB Server Space' },
      { active: true, desc: '2 Users per Project' },
      { active: true, desc: 'Free Domain' },
      { active: true, desc: 'Live Chat Support' },
      { active: false, desc: 'Email Integration' },
      { active: false, desc: 'Unlimited Download' },
    ] 
  }
]

export const servicesData = [
  { 
    header: '01', 
    title: 'VIRTUAL ASSISTANCE', 
    desc:[
    `Welcome to 2in1 Promotions & VA
    Solutions, where we redefine
    productivity and efficiency through
    our top-notch Virtual Assistance
    services. `],
    path: '/services'
  },
  { 
    header: '02', 
    title: 'WEB DEVELOPMENT', 
    desc:[
    `At 2in1, we are committed to
    bringing your digital vision to life
    through our comprehensive Web
    Development services. `,
    ], path: '/services'
  },
  { 
    header: '03', 
    title: 'DIGITAL MARKETING', 
    desc:[
    `Unlock the full potential of your
    online presence with our
    comprehensive Digital Marketing
    services.`,],
    path: '/services'
    },
]

export const skillsData = [
  {  title: 'PHOTOSHOP', percentage: '50%' },
  {  title: 'MARKETING', percentage: '80%' },
  {  title: 'AFFINITY', percentage: '20%' },
  {  title: 'CODING', percentage: '50%' },
  {  title: 'DEVELOPING', percentage: '10%' },
  {  title: 'JAVA', percentage: '50%' }
]

export const testimonialData = [
  {
    name: 'JASON BROWNE',
    message: `“Working with Jayciel on social media content has been a game-changer for our brand. The visual and captions are not only eye-catching but also resonate perfectly with our target audience. Exceptional work, and we're excited for more!”`,
    position: 'Business Owner',
    src: require('../assets/_Customer Testimonial Reviews Instagram Post (2).png')
  },
  {
    name: 'JASON BROWNE',
    message: `“Working with Jayciel on social media content has been a game-changer for our brand. The visual and captions are not only eye-catching but also resonate perfectly with our target audience. Exceptional work, and we're excited for more!”`,
    position: 'Business Owner',
    src: require('../assets/_Customer Testimonial Reviews Instagram Post (3).png')
  },
  {
    name: 'JASON BROWNE',
    message: `“Working with Jayciel on social media content has been a game-changer for our brand. The visual and captions are not only eye-catching but also resonate perfectly with our target audience. Exceptional work, and we're excited for more!”`,
    position: 'Business Owner',
    src: require('../assets/_Customer Testimonial Reviews Instagram Post (4).png')
  },
  
]

export  const timelineData = [
  { date: '2023', title: 'Continuous Improvement', desc: [`Market research to identify potential niches in 2in1 promotions and virtual solutions`,`Formation of the founding team.`,`Initial business plan and strategy development.`] },
  { date: '2022', title: 'Introduce sustainability initiatives within promotions', desc: [`Officially launch the 2in1 promotions and virtual solutions platform`, `Implement marketing strategies to increase user adoption.
  `]  },
  { date: '2021', title: 'International Expansion', desc: [`Introduce new features and improvements based on user feedback`,`Expand the range of virtual solutions offered`,`Establish strategic partnerships with key industry players.`,`Explore collaborations with influencers or brands for promotions.`]  },
  { date: '2020', title: 'Feature Enhancement', desc: [`Explore opportunities for international expansion.`, `Explore partnerships with third-party providers for added value.`,`Develop community engagement strategies.`,`Implement referral programs to encourage user-generated promotion
  `]  },
  { date: '2019', title: 'Official Launch and Marketing', desc: [`Invest in data analytics to provide actionable insights for businesses.`]  },
  { date: '2018', title: 'Company Inception and Ideation', desc: [`Continuously refine and improve the platform based on user feedback.`,`Evaluate and implement emerging technologies in the industry.`,`Celebrate the company's achievements and milestones.`,`Plan for the next phase of growth and innovation.`]  },
]

export const aboutCardData = [
  { header: '5', title: 'Digital Marketing Client' },
  { header: '2', title: 'Web development Client' },
  { header: '3', title: 'Virtual Assistance Client' }, 
]